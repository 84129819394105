import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import metaJson from "../../meta.json";
import { CapacitorLiveUpdate } from "../modules/capacitor/live-update";
import { ConfigItem, getCurrentConfig } from "./config";

const currentConfig: ConfigItem = getCurrentConfig().config;
const sentryDsn: string | undefined = currentConfig.sentry?.dsn;
const logRocket: string | undefined = currentConfig.logRocket;
const sentryEnvironment: string | undefined = currentConfig.sentry?.environment;

/** Hack for web*/
const LiveUpdateUsedVersionPromise: Promise<string | null> =
    Capacitor.isNativePlatform()
        ? CapacitorLiveUpdate.getUsedVersion()
        : new Promise(resolve => resolve(null));

Promise.all([LiveUpdateUsedVersionPromise, Device.getId()]).then(([version, { identifier }]): void => {
    const releaseName = `${metaJson.channel.replace("/","-")}-${version || metaJson.version}`;

    if (sentryDsn) {
        Sentry.init({
            // nc-mobile
            dsn: sentryDsn,
            integrations: [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance, including custom Angular routing instrumentation
                Sentry.browserTracingIntegration(),
                // Registers the Replay integration,
                // which automatically captures Session Replays
                Sentry.replayIntegration(),
            ],
            environment: sentryEnvironment,
            release: releaseName,
            sampleRate: 0.1,
            // Capture Replay for 20% of all sessions,
            // plus for 20% of sessions with an error
            replaysSessionSampleRate: 0.2,
            replaysOnErrorSampleRate: 0.2,
        }, SentryReact.init);

        Sentry.setUser({
            id: identifier,
        });
    }

    if (logRocket && Math.random() <= 0.1) {
        LogRocket.init(logRocket, { release: releaseName });
        setupLogRocketReact(LogRocket);
        LogRocket.getSessionURL((sessionURL) => {
            if (!sentryDsn) {
                return;
            }
            Sentry.getCurrentScope().setExtra("sessionURL", sessionURL);
        });
    }
});
